// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

global.jQuery = require("jquery")

import 'popper.js'
import 'bootstrap'
import 'js-cookie'
import 'vh-check'
import 'aos';

// css
import 'stylesheets/styles'

// custom scripts
import 'scripts/select2.min'
import 'scripts/owl.carousel.min'
import 'scripts/TweenMax.min'
import 'scripts/script'
import 'scripts/cookie-bar'

Rails.start()
Turbolinks.start()
ActiveStorage.start()
