(function() {
  var allowCookies, cookiesAllowed, declineCookies, ready;

  cookiesAllowed = null;

  allowCookies = function() {
    Cookies.set('allow_cookies', 'yes', {
      expires: 365
    });
    jQuery('#cookies-bar').fadeOut(250);
  };

  declineCookies = function() {
    Cookies.set('allow_cookies', 'no', {
      expires: 365
    });
    jQuery('#cookies-bar').fadeOut(250);
  };

  ready = function() {
    cookiesAllowed = Cookies.get('allow_cookies');
    if (cookiesAllowed === 'yes') {
      allowCookies;
    }
    jQuery('a#accept-cookies').on('click', function(e) {
      return allowCookies();
    });
    return jQuery('a#decline-cookies').on('click', function(e) {
      return declineCookies();
    });
  };

  jQuery(document).ready(ready);

  jQuery(document).on('page:load', ready);

  jQuery(document).on('turbolinks:load', ready);

}).call(this);